/* You can add global styles to this file, and also import other style files */
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-alpine.css";
@import "ngx-toastr/toastr";
@import "../node_modules/@fdjlss/ngx-org-chart/theming";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ag-theme-alpine {
  --ag-header-height: 45px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #063178 !important;
  box-shadow: -2px -1px 12px -2px #d6d6d6;
  --ag-header-cell-hover-background-color: #063178;
  --ag-header-cell-moving-background-color: #063178;
  --ag-borders: none;
  --ag-border-color: rgb(209, 209, 209);
  --ag-borders-row: solid 1px;
  --ag-row-border-color: rgb(209, 209, 209);

  --ag-range-selection-border-color: #063178;
  --ag-range-selection-border-style: dashed;

  --ag-data-color: var(--ag-foreground-color);
  --ag-grid-size: 5.3px;
}

.ag-theme-alpine .ag-header {
  font-family: sans-serif;
  font-weight: normal;
}

.ag-theme-alpine .ag-header-group-cell {
  font-weight: normal;
  font-size: 15px;
}

.ag-theme-alpine .ag-header-cell {
  font-weight: 600;
  font-size: 12px;
  color: #009fe3;

}
.ag-ltr .ag-cell {
  border-right-width: 1px;
  font-size: 12px !important;
}

.ag-row {
  border-bottom: none;
}

.rowClass:nth-child(2n + 1):hover {
  background-color: rgb(207 239 252);
}

.rowClass:hover {
  background-color: rgb(49 111 245 / 13%);
  cursor: pointer;
}

.rowClass:nth-child(2n + 1) {
  background-color: #f5f5f5;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.myform-wrapper {
  margin: 50px;
  width: 350px;
}
.myform-wrapper .mat-form-field {
  width: 100%;
}
.myform-wrapper .mat-toolbar-single-row {
  height: auto !important;
  background: transparent;
}
.myform-wrapper .mat-toolbar-single-row button {
  width: 30%;
}

/*
#fileUpload {
  position: absolute;
  z-index: 9;
  opacity: 0;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  cursor: pointer;
} */

.ngx-org-image {
  display: none;
}
.ngx-org-box {
  max-width: 100% !important;
  // height: 33px !important;
  // line-height: 4px !important;
  border-radius: 5px !important;
  width: 100%;
}
.ngx-org-box {
  font-size: 13px;
  padding: 0px 20px;
}

.tagWith:hover {
  // border-left: 4px solid #1070ee !important;
  // border-right: 4px solid #1070ee !important;
  border-radius: 5px !important;
  // box-sizing: border-box !important;
  background-color: #0af01d;
  font-size: 18px !important;
  cursor: pointer !important;
  transition: all 0.1s ease-in-out !important;
}

.mat-mdc-optgroup-label {
  background: #e3e3e3 !important;
}
.sidebar {
  width: 3%; /* Set max-width constraint */
  max-width: 250px; /* Optional: Define a maximum width */
  height: 100%;
  position: fixed;
  left: 0;
  overflow-x: hidden;
  transition: transform 1.0s ease, width 1.0s ease;
  transform: translateX(-100%); /* Initially hidden */
  // background-color: #333; /* Add a background color for visibility */
  color: white; /* Optional: Adjust text color */
}

.sidebar.active {
  transform: translateX(0); /* Slide-in effect */
  width: 250px; /* Expand width when active */
}

.content {
  margin-left: 250px; /* Adjust content margin when sidebar is active */
  transition: margin-left 1.0s ease;
}

.content.collapsed {
  margin-left: 3%; /* Adjust content margin when sidebar is collapsed */
}

@media only screen and (max-width: 1280px) {
  .close-menu {
      transform-origin: 0% 0% !important;
      transform: translate(0%, 0) !important;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0) !important;
  }
}
